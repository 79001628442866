import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button, Checkbox, Form, Input } from 'antd';

// context
import { useAuthState } from 'src/contexts/authContext';

// service
import AuthService from '@services/auth';
import AuthTokenStorageService from '@services/AuthTokenStorageService';

// components
import Alert from '@components/common/alert';

type LayoutType = Parameters<typeof Form>[0]['layout'];

const Login: React.FC = () => {
  const router = useRouter();
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const [renderComp, setRenderComponent] = useState('phone');
  const [token, setToken] = useState(null);
  const { setLogin, isAuthenticated, user } = useAuthState();

  const [errors, setErrors] = useState({
    phonenumber: false,
    confirmationcode: false,
    phonenumberNull: false,
    confirmationcodeNull: false,
  });

  useEffect(() => {
    // getUserInfo();
  }, []);

  const getUserInfo = async () => {
    // const res: any = await UserService.getUserInfo();
    // if (res?.success && res?.data?.role === 'admin') {
    //   router.push('/features');
    //   return;
    // }
  };

  const onFinish = async (values: any) => {
    if (values.phonenumber === undefined || values.phonenumber.length < 8) {
      setErrors({
        phonenumber: false,
        confirmationcode: false,
        phonenumberNull: true,
        confirmationcodeNull: false,
      });
      return;
    } else {
      const res: any = await AuthService.getConfirmationCode({ phoneNumber: values.phonenumber });
      setToken(res.data.token);
      setRenderComponent('code');
    }
  };

  const onFinishCode = async (values: any) => {
    if (values.confirmationcode === undefined) {
      setErrors({
        phonenumber: false,
        confirmationcode: false,
        phonenumberNull: false,
        confirmationcodeNull: true,
      });
      return;
    }
    try {
      const res: any = await AuthService.authenticate({
        code: values.confirmationcode,
        token: token,
      });
      if (res && res.success) {
        await AuthTokenStorageService.store(res?.data?.accessToken);
        await setLogin(res?.data?.accessToken);
        setErrors({
          phonenumber: false,
          confirmationcode: false,
          phonenumberNull: false,
          confirmationcodeNull: false,
        });
        router.push('/categories');
      } else {
        setErrors({
          phonenumber: false,
          confirmationcode: true,
          phonenumberNull: false,
          confirmationcodeNull: false,
        });
      }
    } catch (e) {
      setErrors({
        phonenumber: false,
        confirmationcode: true,
        phonenumberNull: false,
        confirmationcodeNull: false,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onCloseAlert = () => {
    setErrors({
      phonenumber: false,
      confirmationcode: false,
      phonenumberNull: false,
      confirmationcodeNull: false,
    });
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[400px] p-5 py-20">
        {errors.phonenumberNull && (
          <div className="mb-3 mt-4 w-64">
            <Alert
              type="error"
              message="Алдаа гарлаа"
              description="Утасны дугаараа оруулна уу!"
              onClose={onCloseAlert}
            />
          </div>
        )}
        {errors.confirmationcodeNull && (
          <div className="mb-3 mt-4 w-64">
            <Alert
              type="error"
              message="Алдаа гарлаа"
              description="Баталгаажуулах кодоо оруулна уу!"
              onClose={onCloseAlert}
            />
          </div>
        )}
        {errors.confirmationcode && (
          <div className="mb-3 mt-4 w-64">
            <Alert
              type="error"
              message="Алдаа гарлаа"
              description="Баталгаажуулах кодоо буруу байна!"
              onClose={onCloseAlert}
            />
          </div>
        )}
        {renderComp === 'phone' ? (
          <div>
            <div className="text-2xl font-bold mb-5">Get confirmation code</div>
            <div className="border flex items-center justify-start rounded-md p-5">
              <Form
                name="basic"
                form={form}
                layout={formLayout}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size={'large'}
              >
                <Form.Item label="PhoneNumber" name="phonenumber">
                  <Input />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-2xl font-bold mb-5">Fill the code</div>
            <div className="border flex items-center justify-start rounded-md p-5">
              <Form
                name="basic"
                onFinish={onFinishCode}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                layout={formLayout}
                size={'large'}
              >
                <Form.Item label="Confirmation code" name="confirmationcode">
                  <Input />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
